import {Component, OnInit} from '@angular/core';

import {MenuController, Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {NavigationEnd, Router} from '@angular/router';


// declare ga as a function to access the JS code in TS
declare let gtag: Function;


@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        public router: Router
    ) {
        this.initializeApp();

        // https://www.techiediaries.com/integrate-google-analytics-angular-10-9/
        this.router.events.subscribe(event => {

            if (event instanceof NavigationEnd) {
                gtag('set', 'page', event.urlAfterRedirects);
                gtag('send', 'pageview');
            }
        });
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });
    }

    ngOnInit() {
    }

}
