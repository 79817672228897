import {NgModule} from '@angular/core';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
    // {
    //     path: '',
    //     redirectTo: 'blog',
    //     pathMatch: 'full'
    // },
    {
      path: '',
      redirectTo: 'mini-games',
      pathMatch: 'full'
    },
    {
      path: 'mini-games',
      loadChildren: () => import('./features/mini-games/mini-games.module').then(m => m.MiniGamesPageModule)
    },
    {
        path: 'blog',
        loadChildren: () => import('./features/blog/blog.module').then(m => m.BlogModule)
    },
    {
        path: 'generators',
        loadChildren: () => import('./features/generators/generators.module').then(m => m.GeneratorsPageModule)
    },
    {
        path: '**',
        redirectTo: ''
    }
];


const routerOptions: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64],
};

@NgModule({
    imports: [
        RouterModule.forRoot(routes, routerOptions)
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
