import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

  clock = document.getElementById('clock');
  public selectedIndex = 0;
  public selectedSubMenuIndex = 0;

  public appPages = [
    {
      title: 'Minigames',
      url: '/mini-games',
      icon: 'game-controller',
      children: [
        {
          title: 'Setup',
          url: '/mini-games/setup',
          icon: 'beer',
        }
      ]
    },
    {
      title: 'Blog',
      url: '/blog',
      icon: 'clipboard',
      children: []
    },
    {
      title: 'Generators',
      url: '/generators',
      icon: 'cart',
      children: []
    }
  ];

  constructor() {
  }

  ngOnInit() {
    const path = window.location.pathname.split('/' )[1];
    console.log(path)
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.url.toLowerCase() === '/' + path.toLowerCase());
    }
    setInterval(this.clockUpdater, 1000);
  }

  private clockUpdater() {
    const time = new Date();
    // Don't need to call toString, but it's fine here. When you start
    // concatenating numbers onto strings they get converted anyway.
    let hours = time.getHours().toString();
    let minutes = time.getMinutes().toString();
    let seconds = time.getSeconds().toString();
    let day = time.getDate().toString();
    let month = (time.getMonth() + 1).toString();
    const year = time.getFullYear().toString();

    // Your previous logic.
    if (hours.length < 2) {
      hours = '0' + hours;
    }
    if (minutes.length < 2) {
      minutes = '0' + minutes;
    }
    if (seconds.length < 2) {
      seconds = '0' + seconds;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    if (month.length < 2) {
      month = '0' + month;
    }

    const clockStr = day + '.' + month + '.' + year + ' - ' + hours + ':' + minutes + ':' + seconds;

    // Update this class' "el" variable as before.
    this.clock.textContent = clockStr;
  }
}
